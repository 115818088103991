import loadable from "@loadable/component";

const SlidingBannerSection = loadable(
  () => import(/* webpackChunkName: "jac-intools-sliding-banner" */ "./SlidingBanner"),
  {
    fallback: <div />, // what to show during loadingÏ
    ssr: false,
  }
);

export default SlidingBannerSection;
