import loadable from "@loadable/component";

const NewsSection = loadable(
  () => import(/* webpackChunkName: "jac-intools-news" */ "./News"),
  {
    fallback: <div />, // what to show during loadingÏ
    ssr: false,
  }
);

export default NewsSection;
