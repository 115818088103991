import loadable from "@loadable/component";

const LoginLoadable = loadable(
  () => import(/* webpackChunkName: "payment-module-root" */ "./Login"),
  {
    fallback: <div />, // what to show during loadingÏ
    ssr: false,
  }
);

export default LoginLoadable;
