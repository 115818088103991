import { Route, Switch, Redirect } from "react-router-dom";

import MainContainer from "../components/Container/MainContainer";

import { GlobalContextProvider } from "../context";

import Login from "./Login";
import News from "./News";
import Testimony from "./Testimony";
import SlidingBanner from "./SlidingBanner";
import Awards from "./Awards";
import Graduates from "./Graduates";

function Routes() {
  return (
    <GlobalContextProvider>
      <Switch>
        <Redirect exact from="/" to="/sliding-banner" />
        <Route path="/login" component={Login} />
        <MainContainer>
          <Route path="/sliding-banner" component={SlidingBanner} />
          <Route path="/testimony" component={Testimony} />
          <Route path="/graduates" component={Graduates} />
          <Route path="/awards" component={Awards} />
          <Route path="/news" component={News} />
        </MainContainer>
      </Switch>
    </GlobalContextProvider>
  );
}

export default Routes;
