import loadable from "@loadable/component";

const TestimoniSection = loadable(
  () => import(/* webpackChunkName: "jac-intools-testimoni" */ "./Testimony"),
  {
    fallback: <div />, // what to show during loading
    ssr: false,
  }
);

export default TestimoniSection;
