const caseLoading = {
  SET_LOADING: (state, payload) => ({
    ...state,
    loading: payload,
  }),
};

const cases = {
  ...caseLoading,
};

const reducer = (state, action) => {
  try {
    if (process.env.REACT_APP_ENV === "development")
      console.log("bank management reducer", { state, action });
    return cases[action.type](state, action.payload);
  } catch (error) {
    console.error("bank management reducer error", { error, state, action });
  }
};

export default reducer;
