import React, { createContext, useContext, useReducer } from "react";

import reducer from "./reducer";
import initialContext from "./initial-context";

const getInitialContext = () => initialContext;
const GlobalContext = createContext(initialContext);
const useGlobalContext = () => useContext(GlobalContext);

function GlobalContextProvider(props) {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, {}, getInitialContext);

  const value = {
    ...state,
    dispatch,
  };

  if (process.env.REACT_APP_ENV === "development")
    console.log("GLOBAL_CONFIG_CONTEXT", { value });

  return (
    <GlobalContext.Provider {...{ value }}>{children}</GlobalContext.Provider>
  );
}

export { GlobalContextProvider, GlobalContext, useGlobalContext };
