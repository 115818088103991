import { css } from "@emotion/css";

export const cssLayout = css`
  min-height: 100vh;

  .ant-layout-sider-collapsed {
    min-width: 110px !important;
  }

  .ant-layout-sider-trigger {
    min-width: 110px !important;
  }
`;

export const cssLogoContainer = css`
  display: flex;
  height: 64px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  img {
    height: 48px;
  }
`;

export const cssContent = css`
  margin: 0 16px;
`;

export const cssChildrenContainer = css`
  padding: 24px;
  min-height: 360px;
`;
