const init = {
  loading: false,
  news: {},
  awards: {},
  testimony: {},
  slidingBanner: {},
  graduates: {},
};

export default init;
