import loadable from "@loadable/component";

const AwardsSection = loadable(
  () => import(/* webpackChunkName: "jac-intools-awards" */ "./Awards"),
  {
    fallback: <div />, // what to show during loadingÏ
    ssr: false,
  }
);

export default AwardsSection;
