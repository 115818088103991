import loadable from "@loadable/component";

const GraduatesSection = loadable(
  () => import(/* webpackChunkName: "jac-intools-graduates" */ "./Graduates"),
  {
    fallback: <div />, // what to show during loadingÏ
    ssr: false,
  }
);

export default GraduatesSection;
