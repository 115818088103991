import React, { useState, useEffect } from "react";

import { Link, useLocation, useHistory } from "react-router-dom";

import { Layout, Menu } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

import JACLogo from "../../assets/logo_jac.png";

import {
  cssLayout,
  cssContent,
  cssLogoContainer,
  cssChildrenContainer,
} from "./style";

import {
  EditOutlined,
  PictureOutlined,
  UserOutlined,
  TrophyOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import Typography from "antd/es/typography/Typography";

const { Item } = Menu;
const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, url) {
  return {
    key,
    icon,
    label,
    url,
  };
}

const items = [
  getItem("Sliding Banner", "1", <PictureOutlined />, "sliding-banner"),
  getItem("Testimony", "2", <EditOutlined />, "testimony"),
  getItem("Graduate's", "3", <UserOutlined />, "graduates"),
  getItem("Awards", "4", <TrophyOutlined />, "awards"),
  getItem("News", "5", <ExclamationCircleOutlined />, "news"),
];

function MainContainer({ children }) {
  const { pathname } = useLocation();
  const history = useHistory();

  const isCollapseFromLocal = localStorage.getItem("collapsedMenu");
  const isCollapseBoolean = isCollapseFromLocal === "true" || false;
  const [collapsed, setCollapsed] = useState(isCollapseBoolean || false);

  // Update local storage when data changes
  useEffect(() => {
    localStorage.setItem("collapsedMenu", collapsed);
  }, [collapsed]);

  function getSelectedKey() {
    const pathnameWithoutSlash = pathname.substring(1);
    const filteredItem = items.filter(
      (item) => item.url === pathnameWithoutSlash
    );

    return filteredItem[0]?.key;
  }

  return (
    <Layout className={cssLayout}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => {
          setCollapsed(value);
        }}
      >
        <div className={cssLogoContainer}>
          <Link to="/">
            <img src={JACLogo} alt="JAC-Logo" />
          </Link>
        </div>
        <Menu theme="dark" selectedKeys={getSelectedKey()} mode="inline">
          {items.map((item) => (
            <Item key={item.key} icon={item.icon}>
              <Link to={item.url}>{item.label}</Link>
            </Item>
          ))}
        </Menu>
      </Sider>

      <Layout className="site-layout">
        <Header
          style={{
            padding: "0 0 0 12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ color: "white" }}>
            JAC SCHOOL INTERNAL TOOLS
          </Typography>
          <div
            style={{
              display: "flex",
              padding: "20px",
              gap: "20px",
              cursor: "pointer",
            }}
            // onClick={() => {
            //   window.location.href = `${window.location.origin}/login`;
            // }}
            onClick={() => {
              history.push("/login");
            }}
          >
            <Typography strong style={{ color: "white" }}>
              Logout
            </Typography>
            <LogoutOutlined style={{ color: "white", fontSize: "20px" }} />
          </div>
        </Header>
        <Content className={cssContent}>
          <div className={cssChildrenContainer}>{children}</div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          JAC School INTOOLS ©2023 Created by MotMot
        </Footer>
      </Layout>
    </Layout>
  );
}

export default MainContainer;
